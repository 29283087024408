import React from "react";
import { Form, Field } from "@progress/kendo-react-form";

import {
  ValidationInput,
  loginStyle,
  mapping,
  ButtonSolid,
  DropDownListButton,
} from "@aim-mf/styleguide";

const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const emailValidator = (value) =>
  emailRegex.test(value) ? "" : "Please enter a valid email.";

const passwordRegex = new RegExp(/.{8}/);
const passwordValidator = (value) =>
  !value
    ? "Field can not be empty"
    : passwordRegex.test(value)
    ? ""
    : "Please enter a valid password.";

class Login extends React.Component {
  render() {
    // console.log(this.props.tenantList);
    if (Object.keys(this.props.tenantList).length === 0) {
      return <div />;
    }
    const handleSubmit = (dataItem) => {
      // console.log();
      this.props.login(
        dataItem.email,
        dataItem.password,
        Object.keys(this.props.tenantList.tenant_map).find(
          (key) => this.props.tenantList.tenant_map[key] === dataItem.tenant
        )
      );
    };

    return (
      <div style={loginStyle.formBgStyle}>
        <div style={loginStyle.formPadding}>
          <Form
            onSubmit={handleSubmit}
            render={(formRenderProps) => (
              <form onSubmit={formRenderProps.onSubmit}>
                <fieldset>
                  <legend style={mapping["heading/h1/lightleft"]}>
                    Sign In
                  </legend>
                  <div style={loginStyle.tileFieldPadding}>
                    <Field
                      title={"Instance"}
                      name={"tenant"}
                      type={"tenant"}
                      component={DropDownListButton}
                      DropDownButtonStyle={tenantDropDownStyle}
                      value={Object.values(this.props.tenantList.tenant_map)[0]}
                      data={Object.values(this.props.tenantList.tenant_map)}
                    />
                  </div>
                  <div style={{ height: "20px" }} />
                  <div style={loginStyle.fieldsPadding}>
                    <Field
                      title={"Username"}
                      name={"email"}
                      //type={"email"}
                      component={ValidationInput}
                      hint={"Email"}
                      //validator={emailValidator}
                    />
                  </div>
                  <div style={loginStyle.fieldsPadding}>
                    <Field
                      title={"Password"}
                      name={"password"}
                      type={"password"}
                      component={ValidationInput}
                      hint={"Password"}
                      validator={passwordValidator}
                    />
                  </div>
                </fieldset>
                {this.props.loginFail && (
                  <div style={{ color: "red" }}>
                    {" "}
                    Incorrect tenant, username or password{" "}
                  </div>
                )}
                <div style={loginStyle.fieldButtonPadding}>
                  <ButtonSolid
                    name="Sign In"
                    height="48px"
                    width="280px"
                    color="Primary"
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                  />
                </div>
              </form>
            )}
          />
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            style={forgetPasswordStyle}
            onClick={() => {
              this.props.handleForgetPassword();
            }}
            className={"div_hover"}
          >
            forget password ?
          </div>
          <div style={{ height: "40px" }} />
        </div>
      </div>
    );
  }
}

const forgetPasswordStyle = {
  paddingTop: "10px",
  color: mapping["Color_Basic_Primary"],
  textDecoration: "underline",
};

const tenantDropDownStyle = {
  width: "280px",
  height: "40px",
  backgroundColor: mapping["Color_Basic_Secondary"],
  border: "none",
  boxShadow: "none",
};

export { Login };
