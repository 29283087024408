import React from "react";
import { LogoImage, loginStyle } from "@aim-mf/styleguide";

class Logo extends React.Component {
  render() {
    return (
      <div style={{ display: "inline-flex" }}>
        <img style={loginStyle.logo} src={LogoImage} alt={LogoImage} />
      </div>
    );
  }
}

export { Logo };
