import React from "react";
import { LoginPage } from "./page/loginPage";
import { LoginBG } from "@aim-mf/styleguide";
import { fetchAuthAPIWithPermission, usernameLogin } from "@aim-mf/api";
import { navigateToUrl } from "single-spa";

const initialState = {
  loadingTenantList: true,
  loginFail: false,
  forgetPasswordUserNotFound: false,
  tokenError: false,
  tenantList: [],
  passwordResetSuccess: false,
};

function App() {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const {
    loadingTenantList,
    tenantList,
    loginFail,
    forgetPasswordUserNotFound,
    tokenError,
    passwordResetSuccess,
  } = state;

  const login = React.useCallback((username, password, tenant) => {
    //console.log(tenant);
    const loginSubscription = usernameLogin(
      username,
      password,
      tenant
    ).subscribe(
      (results) => {
        if (results.status === 200) {
          dispatch({ type: "loginSuccess" });
        }
      },
      (err) => {
        if (err.response.data.detail === "Incorrect login information") {
          dispatch({ type: "loginFail" });
        }
      }
    );
    return () => loginSubscription.unsubscribe();
  });

  React.useEffect(() => {
    if (loadingTenantList) {
      dispatch({ type: "loadingTenantList" });
      const subscription = fetchAuthAPIWithPermission("login/info").subscribe(
        (results) => {
          //console.log("in post api results");
          dispatch({ type: "loadTenantList", data: results.data });
        },
        (err) => {
                    console.log("err", err); // eslint-disable-line
        }
      );

      return () => subscription.unsubscribe();
    }
  }, [loadingTenantList]);

  const resetPasswordRequest = (data) => {
    dispatch({ type: "passwordResetSuccessReset" });
    dispatch({ type: "forgetPasswordUserNotFoundReset" });
    dispatch({ type: "tokenErrorReset" });
    const subscription = fetchAuthAPIWithPermission("reset/request", {
      data: data,
    }).subscribe(
      (results) => {
        //console.log("in post api results");
        //dispatch({type: "loadTenantList", data: results.data});
      },
      (err) => {
        console.log("err", err); // eslint-disable-line
        dispatch({ type: "forgetPasswordUserNotFound" });
      }
    );
    return () => subscription.unsubscribe();
  };

  const resetPasswordAction = (data) => {
    dispatch({ type: "passwordResetSuccessReset" });
    dispatch({ type: "forgetPasswordUserNotFoundReset" });
    dispatch({ type: "tokenErrorReset" });
    const subscription = fetchAuthAPIWithPermission("reset/action", {
      data: data,
    }).subscribe(
      (results) => {
        //console.log("in post api results");
        //dispatch({type: "loadTenantList", data: results.data});
        dispatch({ type: "passwordResetSuccessReset" });
        dispatch({ type: "forgetPasswordUserNotFoundReset" });
        dispatch({ type: "tokenErrorReset" });
      },
      (err) => {
        console.log("err", err); // eslint-disable-line
        dispatch({ type: "tokenError" });
      }
    );
    return () => subscription.unsubscribe();
  };

  return (
    <LoginPage
      passwordResetSuccess={passwordResetSuccess}
      tenantList={tenantList}
      login={login}
      LoginBG={LoginBG}
      loginFail={loginFail}
      forgetPasswordUserNotFound={forgetPasswordUserNotFound}
      tokenError={tokenError}
      resetPasswordRequest={resetPasswordRequest}
      resetPasswordAction={resetPasswordAction}
    />
  );
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case "loadingTenantList":
      // console.log("in loading riskList")
      return { ...state, loadingTenantList: true };
    case "loadTenantList":
      return {
        ...state,
        tenantList: action.data,
        loadingTenantList: false,
      };
    case "loginFail":
      return {
        ...state,
        loginFail: true,
      };
    case "loginSuccess":
      return {
        ...state,
        loginFail: false,
      };
    case "forgetPasswordUserNotFound":
      return {
        ...state,
        forgetPasswordUserNotFound: true,
      };
    case "forgetPasswordUserNotFoundReset":
      return {
        ...state,
        forgetPasswordUserNotFound: false,
      };
    case "tokenError":
      return {
        ...state,
        tokenError: true,
      };
    case "tokenErrorReset":
      return {
        ...state,
        tokenError: false,
      };
    case "passwordResetSuccess":
      return {
        ...state,
        passwordResetSuccess: true,
      };
    case "passwordResetSuccessReset":
      return {
        ...state,
        passwordResetSuccess: false,
      };
    default:
      throw Error(`Unknown action type '${action.type}'`);
  }
}

export default App;
