import React from "react";
import {
  mapping,
  NonValidationInput,
  ButtonSolid,
  DropDownListButton,
} from "@aim-mf/styleguide";

// this part i think can use AutoComplete from styleguide

class ForgetPasswordPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      tenant: Object.values(this.props.tenantList.tenant_map)[0],
    };
  }

  render() {
    return (
      <div style={RiskCreationFormWrapStyle}>
        <div style={RiskCreationFormStyle}>
          <div style={formBgStyle}>
            <div style={mapping["heading/h5/lightleft"]}>
              Reset your password
            </div>
            <div style={{ height: "50px" }} />
            <div style={mapping["forms/label/1-default"]}>
              Please fill in following information and click "Send reset
              request", an email contains the verification token will be sent to
              your email, and use that token to reset your password.
            </div>
            <div style={{ height: fieldPadding }} />
            <NonValidationInput
              title={"Username"}
              fieldstyle={InputFieldStyle}
              width={"100%"}
              onChange={(event) => {
                // eslint-disable-next-line no-console
                this.setState({ username: event.value });
              }}
            />
            <div
              style={{
                ...mapping["inlinetext/Italicized/lightleft"],
                fontSize: "14px",
                color: mapping["Color_Basic_Danger"],
              }}
            >
              {this.props.usernameError ? "please enter a valid username" : ""}
            </div>
            <div style={{ height: fieldPadding }} />
            <NonValidationInput
              title={"Email"}
              fieldstyle={InputFieldStyle}
              width={"100%"}
              onChange={(event) => {
                // eslint-disable-next-line no-console
                this.setState({ email: event.value });
              }}
            />
            <div
              style={{
                ...mapping["inlinetext/Italicized/lightleft"],
                fontSize: "14px",
                color: mapping["Color_Basic_Danger"],
              }}
            >
              {this.props.emailError ? "please enter a valid email" : ""}
            </div>
            <div style={{ height: fieldPadding }} />
            <DropDownListButton
              title={"Tenant"}
              DropDownButtonStyle={tenantDropDownStyle}
              value={Object.values(this.props.tenantList.tenant_map)[0]}
              data={Object.values(this.props.tenantList.tenant_map)}
              CustomOnChange={(event) => {
                //console.log(event.value);
                this.setState({ tenant: event.value });
              }}
            />
            <div style={{ height: "40px" }} />
            <div
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <ButtonSolid
                name={"cancel"}
                color={"Secondary"}
                width={"79px"}
                height={"39px"}
                clickEvent={this.props.cancelFunction}
              />
              <ButtonSolid
                name={"Send reset request"}
                color={"Primary"}
                width={"177px"}
                height={"39px"}
                clickEvent={() => {
                  this.props.submitFunction(this.state);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const InputFieldStyle = {
  height: mapping["Field_Height"],
  borderWidth: "0px",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  border: "none !important",
  color: mapping["Color_Basic_Light"],
  padding: "4px",
  resize: "none",
  overflow: "hidden",
  //outline:mapping['Field_Outline']+' solid '+borderColor,
  paddingLeft: mapping["Field_padding_Horizontal"],
  paddingRight: mapping["Field_padding_Horizontal"],
  borderRadius: 0,
  outline: "none",
};
const formBgStyle = {
  height: "fit-content",
  width: "450px",
  padding: "23px 18px 43px 18px",
  backgroundColor: mapping["Color_Extra_Half_Transparent"],
  //display: "inline-flex",
  position: "relative",
};
const RiskCreationFormStyle = {
  position: "relative",
  left: "-50%",
};
const RiskCreationFormWrapStyle = {
  position: "absolute",
  left: "50%",
  top: "8rem",
};
const tenantDropDownStyle = {
  width: "100%",
  height: "40px",
  backgroundColor: mapping["Color_Basic_Secondary"],
  border: "none",
  boxShadow: "none",
};
const fieldPadding = "15px";

export { ForgetPasswordPopup };
