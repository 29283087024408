import React from "react";
import { mapping, NonValidationInput, ButtonSolid } from "@aim-mf/styleguide";

// this part i think can use AutoComplete from styleguide

class ResetPasswordPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: this.props.prefillValue.username,
      email: this.props.prefillValue.email,
      tenant: this.props.prefillValue.tenant,
      ou_name: "",
      new_password: "",
      repeat_password: "",
    };
  }

  render() {
    return (
      <div style={RiskCreationFormWrapStyle}>
        <div style={RiskCreationFormStyle}>
          <div style={formBgStyle}>
            <div style={mapping["heading/h5/lightleft"]}>
              Reset your password
            </div>
            <div style={{ height: "50px" }} />
            <div style={mapping["forms/label/1-default"]}>
              please fill in the token you received via email, and new password
            </div>

            <div style={{ height: fieldPadding }} />
            <NonValidationInput
              title={"Username"}
              fieldstyle={InputFieldStyle}
              width={"100%"}
              value={this.props.prefillValue.username}
              disabled={true}
            />
            <div style={{ height: fieldPadding }} />
            <NonValidationInput
              title={"Email"}
              fieldstyle={InputFieldStyle}
              width={"100%"}
              value={this.props.prefillValue.email}
              disabled={true}
            />
            <div style={{ height: fieldPadding }} />
            <NonValidationInput
              title={"Tenant"}
              fieldstyle={InputFieldStyle}
              width={"100%"}
              value={this.props.prefillValue.tenant}
              disabled={true}
            />
            <div style={{ height: fieldPadding }} />
            <NonValidationInput
              title={"Token"}
              fieldstyle={InputFieldStyle}
              width={"100%"}
              onChange={(event) => {
                // eslint-disable-next-line no-console
                this.setState({ token: event.value });
              }}
            />
            <div style={{ height: fieldPadding }} />
            <NonValidationInput
              title={"New password"}
              type={"password"}
              fieldstyle={InputFieldStyle}
              width={"100%"}
              onChange={(event) => {
                // eslint-disable-next-line no-console
                this.setState({ new_password: event.value });
              }}
            />
            <div style={{ height: "1rem" }}>
              <WarningMsg value={this.WarningMsgNew1()} />
            </div>
            <div style={{ height: fieldPadding }} />
            <NonValidationInput
              title={"Repeat password"}
              type={"password"}
              fieldstyle={InputFieldStyle}
              width={"100%"}
              onChange={(event) => {
                // eslint-disable-next-line no-console
                this.setState({ repeat_password: event.value });
              }}
            />
            <div style={{ height: "1rem" }}>
              <WarningMsg value={this.WarningMsgNew()} />
            </div>
            <div style={{ height: "40px" }} />

            <div
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <ButtonSolid
                name={"cancel"}
                color={"Secondary"}
                width={"79px"}
                height={"39px"}
                clickEvent={this.props.cancelFunction}
              />
              <ButtonSolid
                name={"Reset Password"}
                color={"Primary"}
                width={"197px"}
                height={"39px"}
                disabled={
                  this.WarningMsgNew() ||
                  this.WarningMsgNew1() ||
                  this.state.new_password === "" ||
                  this.state.repeat_password === ""
                }
                clickEvent={() => {
                  this.props.submitFunction(this.state);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  WarningMsgNew = () => {
    if (
      this.state.new_password !== "" &&
      this.state.repeat_password !== "" &&
      this.state.new_password !== this.state.repeat_password
    ) {
      return "Passwords do not match, please check again.";
    }
  };
  WarningMsgNew1 = () => {
    const passwordRegex = new RegExp(
      "^(^(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$)?(^(?=.*d)(?=.*[a-z])(?=.*[@#$%^&+=]).{8,32}$)?(^(?=.*d)(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,32}$)?(^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,32}$)?$"
    );
    const passwordValidator = (value) =>
      !value
        ? "Field can not be empty"
        : passwordRegex.test(value)
        ? "valid"
        : "Please enter a valid password.";

    if (
      passwordValidator(this.state.new_password) !== "valid" &&
      this.state.new_password !== ""
    ) {
      return "Please enter a valid new password.";
    }
  };
}

const InputFieldStyle = {
  height: mapping["Field_Height"],
  borderWidth: "0px",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  border: "none !important",
  color: mapping["Color_Basic_Light"],
  padding: "4px",
  resize: "none",
  overflow: "hidden",
  //outline:mapping['Field_Outline']+' solid '+borderColor,
  paddingLeft: mapping["Field_padding_Horizontal"],
  paddingRight: mapping["Field_padding_Horizontal"],
  borderRadius: 0,
  outline: "none",
};
const formBgStyle = {
  height: "fit-content",
  width: "450px",
  padding: "23px 18px 43px 18px",
  backgroundColor: mapping["Color_Extra_Half_Transparent"],
  //display: "inline-flex",
  position: "relative",
};
const RiskCreationFormStyle = {
  position: "relative",
  left: "-50%",
};
const RiskCreationFormWrapStyle = {
  position: "absolute",
  left: "50%",
  top: "8rem",
};
const fieldPadding = "15px";

const WarningMsg = (props) => {
  return (
    <div
      style={{
        ...mapping["inlinetext/Italicized/lightleft"],
        fontSize: "14px",
        color: mapping["Color_Basic_Danger"],
      }}
    >
      {props.value}
    </div>
  );
};

export { ResetPasswordPopup };
